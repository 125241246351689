@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap');

.App {
  margin: 0;
  padding: 0;
  z-index: 0;
  font-size: 10px;
  /* max-width: 100vw; */
}

/* .nav {
  max-width: 100vw;
  padding: 0;
} */

.link {
  color: inherit;
}

.link:hover {
  color: blue;
}

.Hero {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: 'Rubik Mono One', sans-serif;
  flex-wrap: wrap;
  align-items: center;
  height: 100vh;
  line-height: .2;
  background-image: linear-gradient(0deg, rgba(211, 211, 211, 0.4), rgba(211, 211, 211, 0.4)), url('Hero2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  text-transform: uppercase;
}

.title {
  letter-spacing: 1rem;
  padding-top: 8rem;
  font-size: 8rem;
}

.album-title {
  font-size: 2.5rem;
  letter-spacing: .4rem;
  font-family: 'Nunito', sans-serif;
}

.desc {
  font-size: 1.8rem;
  letter-spacing: .2rem;
  font-family: 'Nunito', sans-serif;
}

.button {
  margin-top: 5rem;
}



.About {
  display: grid;
  align-self: center;
  justify-items: center;
  width: 100vw;
  text-align: center;
  font-size: 3rem;
  text-transform: uppercase;
  color: white;
  padding-top: 1rem;
  padding-bottom: 10rem;
  background: #000000;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #434343, #000000);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #434343, #000000); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.about-header {
  color: white;
  font-size: 5rem;
  font-family: 'Roboto', sans-serif;
  letter-spacing: .3rem;
}

p {
  color: white;
  font-family: 'Nunito', sans-serif;
  letter-spacing: .1rem;
}

.card {
  background: transparent;
  max-width: 75%;
}

.Video {
  padding-top: 2rem;
  text-align: center;
  background-image: linear-gradient(0deg, rgba(211, 211, 211, 0.4), rgba(211, 211, 211, 0.4)), url('logo.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  min-width: 100vw;
  height: 100vh;
  align-items: center;
}

.video-heading {
  color: white;
  font-size: 5rem;
  letter-spacing: .3rem;
  text-transform: uppercase;
  margin-top: 4rem;
  margin-bottom: 0rem;
}

.container {
  display: flex;
  flex-direction: column;
  width: 70vw;
  text-transform: uppercase;
  box-sizing: content-box;
}

.slider {
  display: flex;
  overflow-x: scroll;
}


iframe {
  border-radius: 2px; 
  margin: 3rem;
  margin-left: 0;
  min-width: 560px;
  max-width: 560px;
  max-height: 315px;
  min-height: 315px;
  box-shadow: 0 4px 8px 0;
  box-sizing: content-box;
}

.Music {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #000000;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #434343, #000000);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #434343, #000000); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.music-heading {
  font-size: 5rem;
  letter-spacing: .3rem;
  color: white;
}

.music-player {
  margin: 0;
  border: 0; 
  width: 500px; 
  height: 786px;
}

.MusicLinks {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: black;
  padding-top: 4rem;

}

.links-heading {
  color: white;
  font-size: 2rem;
  text-transform: uppercase;
}

.music-links-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  padding-bottom: 4rem;
}

.music-links {
  font-size: 3.8rem;
  padding: 1rem;
}

.bandcamp {
  color: #629aa9;
  font-size: 3.8rem;
}

.bandcamp:hover {
  color: white;
}

.itunes {
  font-size: 2.5rem;
  padding-right: 4px;
  color: red
}

.itunes:hover {
  color: white;
}

.spotify {
  color: #1DB954;
  font-size: 3.8rem;
  background: transparent
}

.spotify:hover {
  color: white;
}

.amazon {
  font-size: 2.8rem;
  color: #146eb4; 
}

.amazon:hover {
  color: white;
}

.Footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 3rem;
  background-color: black;
}

.booking {
  color: white;
  font-size: 1.2rem;
  letter-spacing: .1rem;
}

.author {
  color: white;
  font-size: 1rem;
  letter-spacing: .1rem;
}

.footer-link {
  text-decoration: none;
  color: white;
}

.footer-link:hover {
  color: blue;
}

@media (max-width: 1380px) {
  .Hero {
    height: 100vh;
    line-height: .2;
    justify-content: center;
  }
  
  .title {
    padding-top: 8rem;
    font-size: 6rem;
    letter-spacing: 1.4rem;
  }

  .album-title {
    letter-spacing: .4rem;
    font-size: 2rem;
  }

  .desc {
    font-size: 1rem;
  }
  
  .button {
    margin-top: 5rem;
  }

  .links-heading {
    font-size: 1rem;
    padding-left: 2.6rem;
  }

}

@media (max-width: 1100px) {
  .title {
    font-size: 4.5rem;
    letter-spacing: 1.2rem;
  }
}

@media (max-width: 1000px) {
  .title {
    font-size: 4rem;
    letter-spacing: 1.3rem;
  }
}

@media (max-width: 900px) {
  .Hero {
    height: 100vh;
    line-height: .2;
    justify-content: center;
    background-position: center;
  }
  
  .title {
    letter-spacing: .8rem;
    padding-top: 8rem;
    font-size: 3rem;
  }

  .album-title {
    font-size: 2rem;
  }

  .desc {
    font-size: 1rem;
  }
  
  .button {
    margin-top: 5rem;
  }

  .music-player {
    max-width: 75%;
  }

  .about-header {
    font-size: 4rem;
  }

  .video-heading {
    font-size: 4rem;
  }

  .music-heading {
    font-size: 4rem;
  }

}



@media (max-width: 776px) {

  .Hero {
    height: 80vh;
    width: 100vw;
    margin-bottom: -2rem;
    padding: 0;
    background-image: linear-gradient(0deg, rgba(211, 211, 211, 0.4), rgba(211, 211, 211, 0.4)), url('hero-mobile.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .title {
    font-size: 4rem;
    letter-spacing: 0rem;
  }

  .album-title {
    font-size: 2rem;
    letter-spacing: .2rem;
    padding: 0;
  }

  .desc {
    font-size: 1.4rem;
    letter-spacing: .1rem;
  }

  .links-heading {
    padding-left: 3rem;
  }

  .VideoMobile {
    display: grid;
    align-self: center;
    justify-items: center;
    width: 100vw;
    text-align: center;
    font-size: 3rem;
    text-transform: uppercase;
    color: white;
    padding-top: 1rem;
    padding-bottom: 10rem;
    background-image: linear-gradient(0deg, rgba(211, 211, 211, 0.4), rgba(211, 211, 211, 0.4)), url('logo.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .video-heading {
    padding-bottom: 4rem;
  }

  .video-card {
    max-width: 95%;
  }

  iframe {
    margin-left: 0;
    margin-right: 0;
  }
  
  

}

@media (max-width: 675px) {

  .Hero {
    justify-content: center;
  }

  .title {
    padding: 0;
  }

  .album-title {
    font-size: .8rem;
    letter-spacing: .2rem;
    padding: 0;
  }

  .desc {
    font-size: .5rem;
    letter-spacing: .1rem;
  }

  .Video {
    background-position: center;
  }

  .video {
    max-width: 75%;
    margin-top: 1rem;
    height: 220px;
  }

  .title {
    font-size: 3rem;
  }
}

@media (max-width: 576px) {

  .Hero {
    height: 100vh;
    margin-bottom: -10rem;
    margin-top: -4.5rem
  }

  .brand {
    font-size: .7rem;
  }

  .title {
    font-size: 1.4rem;
    letter-spacing: .6rem;
  }

  .album-title {
    font-size: .7rem;
    letter-spacing: .3rem;
  }

  .desc {
    font-size: .7rem;
    letter-spacing: .1rem;
  }

  .About {
    padding-bottom: 7rem;
  }

  .about-header {
    font-size: 2.5rem;
  }

  .card {
    max-width: 90%;
  }

  .video-heading {
    font-size: 2.5rem;
    padding-bottom: 4rem;
  }

  .video {
    height: 160px;
  }



  .music-heading {
    font-size: 2.5rem;
  }

  .MusicLinks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    padding-top: 4rem;
  }

  .links-heading {
    font-size: .6rem;
    align-self: center;
    margin-left: 1rem;
    padding-right: 2rem;
  }
  
  
  .music-links {
    margin: .2rem;
    padding: .2rem;
  }
  
  
  .Footer {
    font-size: 1rem;
    max-width: 100vw;
    padding: 0;
  }

  .booking {
    font-size: .8rem;
    font-weight: lighter;
    letter-spacing: .1rem;
    line-height: 2;
  }

  .author {
    font-size: .8rem;
    font-weight: lighter;
    letter-spacing: .1rem;
  }
}

@media (max-width: 414px) {
  .Hero {
    margin-top: -6.5rem;
  }
}

@media (max-width: 411px) {
  .Hero {
    margin-top: -10rem;
    margin-bottom: -14rem;
  }
}

@media (max-width: 380px) {
  .Hero {
    margin-top: -10.5rem;
    margin-bottom: -15rem;
  }
}

@media (max-width: 360px) {
  .Hero {
    height: 100vh;
    margin-top: -5.5rem;
    margin-bottom: -9rem;
  }
}

@media (max-width: 320px) {

  .Hero {
    height: 100vh;
    margin-bottom: -10rem;
    margin-top: -4.5rem
  }

  .brand {
    font-size: .8rem;
  }
  .title {
    font-size: 1.2rem;
    letter-spacing: .5rem;
  }

  .album-title {
    font-size: .7rem;
    letter-spacing: .2rem;
  }

  .desc {
    font-size: .5rem;
    letter-spacing: .15rem;
  }

  .music-links-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    padding-bottom: 4rem;
  }
  
  .music-links {
    margin: .1rem;
    padding: .1rem;
  }
  
  .bandcamp {
    color: #629aa9;
    font-size: 3.8rem;
  }
  
  .itunes {
    font-size: 1.7rem;
    padding-right: 4px;
    color: red
  }
  
  .spotify {
    color: #1DB954;
    font-size: 3.8rem;
    background: transparent
  }
  
  .amazon {
    font-size: 2rem;
    color: #146eb4; 
  }

  .booking {
    font-size: .5rem;
    font-weight: lighter;
    letter-spacing: .1rem;
    line-height: 2;
  }

}

@media (max-width: 280px) {

  .Hero {
    height: 100vh;
    margin-top: -8.5rem;
    margin-bottom: -12rem;
  }

  .brand {
    font-size: .6rem;
  }

  .links-heading {
    font-size: .5rem;
  }

  .music-links-container {
    max-width: 95%;
    padding: 0;
    padding-bottom: 2rem;
  }
}